import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Spin, message, Table, Button, Form, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined /* , CopyOutlined */ } from '@ant-design/icons';
import { SERVER_URL } from '../config';
import EditMachineModal from './EditMachineModal';
import confirmModal from './confirm';

const EditMachine = (props) => {
  const [machines, setMachines] = useState([]);
  const [stitches, setStitches] = useState([]);
  const [isFormVisible, setFormVisible] = useState(false);
  const [editId, setEditId] = useState(null);
  const [editName, setEditName] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [trigger, setTrigger] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchMachines = async () => {
      setUpdating(true);
      try {
        const { data } = await Axios.get(`${SERVER_URL}/v2/machines`, { withCredentials: true });
        if (data) setMachines(data);
        setUpdating(false);
      } catch (error) {
        console.log(error);
        setUpdating(false);
      }
    };
    fetchMachines();
  }, [trigger]);

  useEffect(() => {
    const fetchStitches = async () => {
      setUpdating(true);
      try {
        const { data } = await Axios.get(`${SERVER_URL}/v2/stitches`, { withCredentials: true });
        if (data) setStitches(data);
        setUpdating(false);
      } catch (error) {
        console.log(error);
        setUpdating(false);
      }
    };
    fetchStitches();
  }, []);

  const editHandler = (record) => {
    if (record) {
      form.setFieldsValue({ number: record.number, stitches: record.stitches });
      setEditId(record._id);
      setEditName(record.number);
    } else {
      form.setFieldsValue({ number: null, stitches: [] });
      setEditId(null);
      setEditName(null);
    }
    setTimeout(() => setFormVisible(true));
  };

  // const duplicateHandler = (record) => {
  //   if (record) {
  //     const duplicateNumber = record.number + ' copy';
  //     form.setFieldsValue({ number: duplicateNumber, stitches: record.stitches });
  //     setEditId(null);
  //     setEditName(duplicateNumber);
  //   }
  //   setTimeout(() => setFormVisible(true));
  // };

  const onEditCancel = () => {
    form.resetFields();
    setFormVisible(false);
    setEditId(null);
    setEditName(null);
  };

  const updateMachine = async ({ number, stitches }, id) => {
    setUpdating(true);
    try {
      const method = id ? 'put' : 'post';
      const route = id ? `${SERVER_URL}/v2/machines/${id}` : `${SERVER_URL}/v2/machines`;

      await Axios[method](
        route,
        { name: 'Singer', number, stitches },
        {
          withCredentials: true,
        }
      );

      message.success(`${id ? 'Updated' : 'Saved'} ${number} machine`);
      setTrigger(!trigger);
      onEditCancel();
      setUpdating(false);
    } catch (err) {
      const msg =
        err.response?.data?.message || `Error ${id ? 'updating' : 'saving'} ${number} machine! Try again later...`;
      console.log('Machine update error: ', err.response?.data?.message || err.message);
      message.error(msg);
      setUpdating(false);
    }
  };

  const deleteMachine = async (id, name) => {
    setUpdating(true);
    try {
      await Axios.delete(`${SERVER_URL}/v2/machines/${id}`, {
        withCredentials: true,
      });

      message.success(`${name || 'Machine'} deleted`);
      setTrigger(!trigger);
      onEditCancel();
      setUpdating(false);
    } catch (err) {
      console.log('Machine delete error: ', err.message || err.response.data.message);
      message.error('Error deleting machine! Try again later...');
      setUpdating(false);
    }
  };

  const columns = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Stitches',
      dataIndex: 'stitches',
      key: 'stitches',
      render: (array) => array && Array.isArray(array) && array.length,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => text && new Date(text).toLocaleDateString(),
      responsive: ['md'],
    },
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => text && new Date(text).toLocaleDateString(),
      responsive: ['md'],
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_, record) => (
        <div style={{ textAlign: 'center', zIndex: 9999 }}>
          <span
            onClick={() => editHandler(record)}
            title={`Edit ${record.number} machine`}
            style={{
              padding: '0.225rem',
              margin: '0.125rem',
              color: '#1890ff',
              textAlign: 'center',
              cursor: 'pointer',
              fontSize: '1.125rem',
            }}
          >
            <EditOutlined />
          </span>

          {/* <span
            onClick={() => duplicateHandler(record)}
            title={`Duplicate ${record.number} machine`}
            style={{
              padding: '0.225rem',
              margin: '0.125rem',
              color: 'darkcyan',
              textAlign: 'center',
              cursor: 'pointer',
              fontSize: '1.125rem',
            }}
          >
            <CopyOutlined />
          </span> */}

          <Popconfirm
            placement='left'
            title={`Are You sure You want to delete ${record.number} machine?`}
            onConfirm={() =>
              confirmModal({
                title: <strong>You are about to delete {record.number} machine</strong>,
                content: (
                  <p>
                    This action is irreversible.
                    <br />
                    Are You sure You want to proceed?
                  </p>
                ),
                onOk: () => deleteMachine(record._id, record.number),
                onCancel: () => {},
              })
            }
          >
            <span
              title={`Delete ${record.number} machine`}
              style={{
                padding: '0.225rem',
                margin: '0.125rem',
                color: 'red',
                textAlign: 'center',
                cursor: 'pointer',
                fontSize: '1.125rem',
              }}
            >
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Spin spinning={updating} tip='Working...'>
      <div style={{ marginBottom: '2rem', display: 'flex', flexDirection: 'row' }}>
        <Button type='primary' onClick={() => editHandler()} disabled={updating}>
          Add new machine
        </Button>
      </div>

      <div style={{ overflowX: 'auto' }}>
        <Table
          bordered
          rowKey={(record) => record._id}
          dataSource={machines}
          loading={updating}
          columns={columns}
          size='small'
          pagination={{ pageSize: '20', size: 'small', hideOnSinglePage: true }}
        />
      </div>

      <EditMachineModal
        visible={isFormVisible}
        id={editId}
        stitches={stitches}
        name={editName}
        setFormVisible={setFormVisible}
        form={form}
        onEditCancel={onEditCancel}
        updateMachine={updateMachine}
        deleteMachine={deleteMachine}
      />
    </Spin>
  );
};

export default EditMachine;
