import React, { useState } from 'react';
import Axios from 'axios';
import { useAppContext } from '../context/AppContext';
import { Form, Input, Button, message } from 'antd';
import { SERVER_URL } from '../config';

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const tailLayout = {
  wrapperCol: { span: 20, offset: 4 },
};

const Login = ({ handleLogin }) => {
  const [forgot, setForgot] = useState(false);
  const { setFetchTrigger } = useAppContext();

  const onFinish = async (values) => {
    if (!forgot) {
      await handleLogin(values);
      setFetchTrigger((prev) => !prev);
      return;
    }

    try {
      await Axios.post(`${SERVER_URL}/forgot-password`, values, { withCredentials: true });
      message.info('Request for password reset sent. Check Your email!', 5);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      message.error(msg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div style={{ maxWidth: '600px', padding: '3rem 1rem', margin: '0 auto' }}>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <h1 style={{ marginBottom: '2rem' }}>Singer Stitch App - {forgot ? 'Forgot password' : 'Login'}</h1>
        {!forgot && (
          <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item
              label='Email'
              name='username'
              rules={[{ required: true, message: 'Please enter your email or username!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='Password'
              name='password'
              rules={[{ required: true, message: 'Please enter your password!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button style={{ marginRight: '1rem' }} onClick={() => setForgot(true)}>
                Reset Password
              </Button>

              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}

        {forgot && (
          <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Please enter your email!' }]}>
              <Input type='email' />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button style={{ marginRight: '1rem' }} onClick={() => setForgot(false)}>
                Cancel
              </Button>

              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};

export default Login;
