import React, { useState } from 'react';
import Axios from 'axios';
import { useAppContext } from '../context/AppContext';
import { Spin, message, Table, Button, Form, Input, Select, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../config';
import confirmModal from './confirm';

const EditLanguageGroup = () => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [editId, setEditId] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [form] = Form.useForm();

  const { languages, languageGroups, setFetchTrigger } = useAppContext();

  const editHandler = (record) => {
    if (record) {
      form.setFieldsValue({ name: record.name, languages: record.languages.map((l) => l._id) });
      setEditId(record._id);
    } else {
      form.setFieldsValue({ name: null, languages: [] });
      setEditId(null);
    }
    setTimeout(() => setFormVisible(true));
  };

  const onEditCancel = () => {
    form.resetFields();
    setEditId(null);
    setFormVisible(false);
  };

  const updateLanguageGroup = async ({ name, languages: groupLanguages }, id) => {
    setUpdating(true);
    try {
      const method = id ? 'put' : 'post';
      const route = id ? `${SERVER_URL}/v2/language-groups/${id}` : `${SERVER_URL}/v2/language-groups`;
      await Axios[method](
        route,
        { name, languages: groupLanguages },
        {
          withCredentials: true,
        }
      );
      message.success(`${id ? 'Updated' : 'Saved'} ${name} language group`);
      setFetchTrigger((prev) => !prev);
      onEditCancel();
      setUpdating(false);
    } catch (err) {
      console.log('Language update error: ', err.message || err.response.data.message);
      message.error(`Error ${id ? 'updating' : 'saving'} ${name} language group! Try again later...`);
      setUpdating(false);
    }
  };

  const deleteLanguageGroup = async (id, name) => {
    setUpdating(true);
    try {
      const res = await Axios.delete(`${SERVER_URL}/v2/language-groups/${id}`, {
        withCredentials: true,
      });
      message.success(`${res?.data?.name || 'Language group'} deleted`);
      setFetchTrigger((prev) => !prev);
      onEditCancel();
      setUpdating(false);
    } catch (err) {
      console.log('Language group delete error: ', err.message || err.response.data.message);
      message.error('Error deleting language group! Try again later...');
      setUpdating(false);
    }
  };

  const columns = [
    {
      title: 'Language Group',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Languages',
      dataIndex: 'languages',
      render: (text) => text && text.map((l) => l.name).join(', '),
      key: 'languages',
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => text && new Date(text).toLocaleDateString(),
      responsive: ['md'],
    },
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => text && new Date(text).toLocaleDateString(),
      responsive: ['md'],
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_, record) => (
        <div style={{ textAlign: 'center', zIndex: 9999 }}>
          <span
            onClick={() => editHandler(record)}
            title={`Edit ${record.name} language group`}
            style={{
              padding: '0.225rem',
              margin: '0.125rem',
              color: '#1890ff',
              textAlign: 'center',
              cursor: 'pointer',
              fontSize: '1.125rem',
            }}
          >
            <EditOutlined />
          </span>

          <Popconfirm
            placement='left'
            title={`Are You sure You want to delete ${record.name} language group?`}
            onConfirm={() =>
              confirmModal({
                title: <strong>You are about to delete {record.name} language group</strong>,
                content: (
                  <p>
                    This action is irreversible.
                    <br />
                    Are You sure You want to proceed?
                  </p>
                ),
                onOk: () => deleteLanguageGroup(record._id),
                onCancel: () => {},
              })
            }
          >
            <span
              title={`Delete ${record.name} language group`}
              style={{
                padding: '0.225rem',
                margin: '0.125rem',
                color: 'red',
                textAlign: 'center',
                cursor: 'pointer',
                fontSize: '1.125rem',
              }}
            >
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Spin spinning={updating} tip='Working...'>
      <div style={{ marginBottom: '2rem', display: 'flex', flexDirection: 'row' }}>
        <Button type='primary' onClick={() => editHandler()} disabled={updating}>
          Add new group
        </Button>

        {isFormVisible && (
          <div style={{ marginLeft: '5rem' }}>
            <Form form={form} onFinish={(values) => updateLanguageGroup(values, editId)} layout='inline'>
              <Form.Item
                name='name'
                label='Name'
                rules={[{ required: true, message: 'Language group name is required!' }]}
              >
                <Input type='text' />
              </Form.Item>

              <Form.Item
                name='languages'
                label='Languages'
                rules={[{ required: true, type: 'array', min: 2, message: 'You must select at least 2 languages!' }]}
              >
                <Select mode='multiple' allowClear showSearch optionFilterProp='children' style={{ width: '150px' }}>
                  {languages?.length > 0 &&
                    languages.map((lang) => (
                      <Select.Option key={lang._id} value={lang._id}>
                        {lang.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Submit
                </Button>

                <Button type='secondary' style={{ marginLeft: '1rem' }} onClick={onEditCancel}>
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>

      <div style={{ overflowX: 'auto' }}>
        <Table
          bordered
          rowKey={(record) => record._id}
          dataSource={languageGroups}
          loading={updating}
          columns={columns}
          size='small'
          pagination={{ pageSize: '20', size: 'small', hideOnSinglePage: true }}
        />
      </div>
    </Spin>
  );
};

export default EditLanguageGroup;
