import { Modal, Form, Button, Input, Checkbox, Row, Col } from 'antd';
import React from 'react';

const EditMachineModal = ({ visible, id, name, stitches, setFormVisible, form, onEditCancel, updateMachine }) => {
  return (
    <Modal
      centered
      width='1000px'
      visible={visible}
      title={`${id ? 'Edit ' + name : 'Add new machine'}`}
      footer={null}
      onCancel={() => setFormVisible(false)}
      destroyOnClose
    >
      <Form form={form} onFinish={(values) => updateMachine(values, id)} layout='horizontal' preserve={false}>
        <Form.Item name='number' label='Number' rules={[{ required: true, message: 'Machine number is required!' }]}>
          <Input type='text' style={{ maxWidth: '200px' }} />
        </Form.Item>

        <Form.Item name='stitches' label='Stitches' style={{ margin: '1rem 0' }}>
          <Checkbox.Group
            style={{
              maxHeight: '500px',
              overflow: 'auto',
              border: '1px solid #d9d9d9',
              borderRadius: '2px',
              padding: '0.5rem',
            }}
          >
            <Row>
              {stitches?.length > 0 &&
                stitches.map((stitch) => (
                  <Col span={3} key={stitch._id}>
                    <Checkbox value={stitch._id} style={{ lineHeight: '32px' }}>
                      <span>{stitch.appNumber}</span>
                      <span style={{ marginLeft: '8px' }}>
                        <img src={stitch.image} height={18} alt={stitch.appNumber} />
                      </span>
                    </Checkbox>
                  </Col>
                ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item style={{ textAlign: 'center', marginTop: '2rem' }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>

          <Button type='secondary' style={{ marginLeft: '1rem' }} onClick={onEditCancel}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditMachineModal;
