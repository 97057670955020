import React from 'react';
import { Tabs } from 'antd';
import Dashboard from './Dashboard';
import EditLanguage from './EditLanguage';
import EditLanguageGroup from './EditLanguageGroup';
import EditMachine from './EditMachine';
import EditTranslation from './EditTranslation';

const { TabPane } = Tabs;

const Edit = (props) => {
  return (
    <Dashboard current={['edit']} {...props}>
      <div style={{ padding: '2rem' }}>
        <Tabs>
          <TabPane tab='Languages' key='Language' destroyInactiveTabPane>
            <EditLanguage />
          </TabPane>

          <TabPane tab='Language Groups' key='Group' destroyInactiveTabPane>
            <EditLanguageGroup />
          </TabPane>

          <TabPane tab='Machines' key='Machine' destroyInactiveTabPane>
            <EditMachine />
          </TabPane>

          <TabPane tab='Translations' key='Translations' destroyInactiveTabPane>
            <EditTranslation />
          </TabPane>
        </Tabs>
      </div>
    </Dashboard>
  );
};

export default Edit;
