import { Modal, Form, Button, Input, Select } from 'antd';
import React from 'react';

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

const tailLayout = {
  wrapperCol: { span: 24 },
};

const EditTranslationModal = ({
  visible,
  id,
  name,
  stitches,
  setFormVisible,
  form,
  onEditCancel,
  updateTranslation,
}) => {
  return (
    <Modal
      centered
      width='800px'
      visible={visible}
      title={`${id ? 'Edit translation of ' + name : 'Add new translation'}`}
      footer={null}
      onCancel={() => setFormVisible(false)}
      destroyOnClose
    >
      <Form {...layout} form={form} onFinish={(values) => updateTranslation(values, id)} preserve={false}>
        <Form.Item name='stitch' label='Stitch'>
          <Select style={{ width: '100px' }}>
            {stitches?.length > 0 &&
              stitches.map((stitch) => (
                <Select.Option value={stitch._id} key={stitch._id}>
                  <span>{stitch.appNumber}</span>
                  <span style={{ marginLeft: '8px' }}>
                    <img src={stitch.image} height={18} alt={stitch.appNumber} />
                  </span>
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item name='name' label='Name'>
          <Input type='text' style={{ maxWidth: '300px' }} />
        </Form.Item>

        <Form.Item name='application' label='Application'>
          <Input type='text' style={{ maxWidth: '300px' }} />
        </Form.Item>

        <Form.Item name='description' label='Description'>
          <Input.TextArea rows={8} type='text' style={{ maxWidth: '640px' }} />
        </Form.Item>

        <Form.Item {...tailLayout} style={{ textAlign: 'center' }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>

          <Button type='secondary' style={{ marginLeft: '1rem' }} onClick={onEditCancel}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditTranslationModal;
