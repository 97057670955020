import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  const { allowed, isAdmin } = rest;

  if (allowed === 'superadmin' && !isAdmin) {
    return <Redirect to='/' />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoggedIn ? ( // if signed in
          <Component {...props} {...rest} /> // render component
        ) : (
          <Redirect to='/login' /> // else redirect
        );
      }}
    />
  );
};

export default ProtectedRoute;
