import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Spin, Button, message } from 'antd';
import { DownloadOutlined, SettingOutlined } from '@ant-design/icons';
import { useAppContext } from '../context/AppContext';
import useData from '../hooks/useData';
import Dashboard from './Dashboard';
import { SERVER_URL, PDF_URL } from '../config';

const SingleLanguage = (props) => {
  const [machines, setMachines] = useData('', []);
  const [isGenerating, setGenerating] = useState(false);
  let history = useHistory();
  const { setPdfGenerating } = useAppContext();

  useEffect(() => {
    setMachines(`${SERVER_URL}/v2/machines/${props.match.params.langId}`, []);
  }, [props.match.params.langId, setMachines, isGenerating]);

  const generatePdf = async (lang, mach) => {
    setGenerating(mach);
    setPdfGenerating(true);
    try {
      const { data } = await Axios.get(`${SERVER_URL}/v2/pdf-generate?machine=${mach}&lang=${lang}`, {
        withCredentials: true,
      });

      message.success(`Generated PDF for ${data.name} in ${lang}`);
      setGenerating(false);
      setPdfGenerating(false);
      history.push({ pathname: '/empty' });
      history.replace({ pathname: props.location.pathname });
    } catch (err) {
      const msg = err.response?.data?.message ? err.response.data.message : err.message;
      console.log('\n generate pdf error = ', msg);
      message.error(msg || 'ERROR generating PDF! Try again later...');
      setGenerating(false);
      setPdfGenerating(false);
    }
  };

  return (
    <Dashboard current={['single', props.match.params.langId]} {...props}>
      {machines.isLoading && (
        <div style={{ textAlign: 'center', marginTop: '5rem' }}>
          <Spin size='large' />
        </div>
      )}

      {machines.isError && <h3 style={{ textAlign: 'center', marginTop: '5rem' }}>ERROR</h3>}

      {!machines.isLoading && !machines.isError && machines.data.machines && (
        <div style={{ textAlign: 'center', padding: '1rem' }}>
          <h3 style={{ marginBottom: '1.5rem' }}>
            {props.match.params.langId} - {machines.data.machines.length} machines
          </h3>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            {machines.data.machines.map((machine, index) => (
              <div
                key={index}
                style={{
                  flexGrow: 1,
                  margin: '2px',
                  padding: '0 5px',
                  maxWidth: '380px',
                  border: '1px solid #ccc',
                  textAlign: 'left',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <p style={{ display: 'inline-block', width: '160px', margin: '5px 0' }}>
                  {machine.name} {machine.number}
                </p>

                <p style={{ display: 'inline-block', width: '120px', margin: '5px 0' }}>stitches: {machine.stitches}</p>

                <p style={{ display: 'inline-block', width: '50px', margin: '5px 0' }}>
                  {machine.pdfFile && (
                    <Button
                      type='primary'
                      href={`${PDF_URL}${machine.pdfFile}`}
                      target='_blank'
                      title={`Open ${machine.pdfFile}`}
                      disabled={isGenerating}
                    >
                      <DownloadOutlined />
                    </Button>
                  )}
                </p>

                {isGenerating !== machine._id && (
                  <p style={{ display: 'inline-block', width: '50px', margin: '5px 0' }}>
                    <Button
                      type='secondary'
                      onClick={() => generatePdf(props.match.params.langId, machine._id)}
                      title={`Generate ${props.match.params.langId} PDF for ${machine.number}`}
                      disabled={isGenerating || machine.stitches === 0}
                      style={{ backgroundColor: machine.newVersion ? '#ffff00' : 'inherit' }}
                    >
                      <SettingOutlined />
                    </Button>
                  </p>
                )}

                {isGenerating && isGenerating === machine._id && (
                  <p style={{ display: 'inline-block', width: '50px', margin: '5px 0' }}>
                    <Button
                      type='secondary'
                      disabled
                      title='Working...'
                      shape={isGenerating && isGenerating === machine._id ? 'round' : ''}
                      style={{ backgroundColor: machine.newVersion ? '#ffff00' : 'inherit' }}
                    >
                      <SettingOutlined spin />
                    </Button>
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </Dashboard>
  );
};

export default SingleLanguage;
