import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const confirm = ({ title, content, onOk, onCancel }) => {
  const showConfirm = Modal.confirm({
    centered: true,
    title,
    icon: <ExclamationCircleOutlined />,
    content,
    onOk,
    onCancel,
  });

  return showConfirm;
};

export default confirm;
