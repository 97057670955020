import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { Table, Tag, Button, message, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import Dashboard from './Dashboard';
import { SERVER_URL } from '../config';

const TAG_COLOR = { admin: 'blue', superadmin: '#1890ff', active: 'green', blocked: 'red', verification: 'lime' };

const Users = (props) => {
  const [users, setUsers] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [fetchTrigger, setTrigger] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const history = useHistory();

  useEffect(() => {
    const fetchUsers = async () => {
      setFetching(true);
      try {
        const { data } = await Axios.get(`${SERVER_URL}/users`, { withCredentials: true });
        if (data) setUsers(data);
        setFetching(false);
      } catch (error) {
        const msg = error.response ? error.response.data.message : error.message;
        message.error(msg);
        setFetching(false);
      }
    };
    fetchUsers();
  }, [fetchTrigger]);

  useEffect(() => {
    const email = sessionStorage.getItem('email');
    if (email) setUserEmail(email);
  }, []);

  const deleteUser = async (id, email) => {
    try {
      await Axios.delete(`${SERVER_URL}/user/${id}`, { withCredentials: true });
      message.info(`User ${email} deleted`);
      setTrigger(!fetchTrigger);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      message.error(msg);
    }
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      responsive: ['md'],
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text) => <Tag color={TAG_COLOR[text]}>{text}</Tag>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Tag color={TAG_COLOR[text]}>{text}</Tag>,
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => text && new Date(text).toLocaleDateString(),
      responsive: ['md'],
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_, record) => (
        <div style={{ textAlign: 'center', zIndex: 9999 }}>
          <span
            onClick={() => history.push(`/user/${record._id}`)}
            title={`Edit ${record.email}`}
            style={{
              padding: '0.225rem',
              margin: '0.125rem',
              color: '#1890ff',
              textAlign: 'center',
              cursor: 'pointer',
              fontSize: '1.125rem',
            }}
          >
            <EditOutlined />
          </span>

          <Popconfirm
            placement='left'
            title={`Are You sure You want to delete ${record.email}?`}
            disabled={record.email === userEmail}
            onConfirm={() => deleteUser(record._id, record.email)}
          >
            <span
              title={record.email === userEmail ? "You can't delete yourself" : `Delete ${record.email}`}
              style={{
                padding: '0.225rem',
                margin: '0.125rem',
                color: 'red',
                textAlign: 'center',
                cursor: record.email === userEmail ? 'not-allowed' : 'pointer',
                fontSize: '1.125rem',
              }}
            >
              <DeleteOutlined style={{ color: record.email === userEmail ? 'lightgray' : 'inherit' }} />
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Dashboard current={['users']} {...props}>
      <div style={{ padding: '2rem' }}>
        <div style={{ marginBottom: '2rem' }}>
          <Button type='primary' onClick={() => history.push('/user/new')}>
            Add new user
          </Button>
        </div>

        <div style={{ overflowX: 'auto' }}>
          <Table
            bordered
            rowKey={(record) => record._id}
            dataSource={users}
            loading={isFetching}
            columns={columns}
            size='small'
            pagination={{ pageSize: '20', size: 'small', hideOnSinglePage: true }}
          />
        </div>
      </div>
    </Dashboard>
  );
};

export default Users;
