import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Button, Spin, message, Form, Input, Select } from 'antd';
import Dashboard from './Dashboard';
import { SERVER_URL } from '../config';

const tailLayout = {
  wrapperCol: { span: 10, offset: 4 },
};

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 10 },
};

const User = (props) => {
  const isNew = props.match.params.id === 'new';
  const history = useHistory();
  const [form] = Form.useForm();
  const [isFetching, setFetching] = useState(!isNew);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await Axios.get(`${SERVER_URL}/user/${props.match.params.id}`, { withCredentials: true });
        if (data) {
          setFetching(false);
          form.setFieldsValue(data);
        }
      } catch (error) {
        const msg = error.response ? error.response.data.message : error.message;
        setFetching(false);
        message.error(msg);
      }
    };
    if (props.match.params.id !== 'new') {
      fetchUser();
    }
  }, [props.match.params.id, form]);

  const updateUser = async (values) => {
    const method = isNew ? 'post' : 'put';
    const route = isNew ? `${SERVER_URL}/register` : `${SERVER_URL}/user/${props.match.params.id}`;

    if (isNew) {
      values.password = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    try {
      await Axios[method](route, values, { withCredentials: true });
      message.info(`User ${isNew ? 'registered' : 'updated'}!`, 2);
      setTimeout(() => history.push('/users'), 2050);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      message.error(msg);
    }
  };

  const onCancel = () => {
    return history.push('/users');
  };

  return (
    <Dashboard current={['users']} {...props}>
      <Spin spinning={isFetching} tip='Working...'>
        <div style={{ padding: '2rem' }}>
          <Form
            {...layout}
            form={form}
            initialValues={{ username: '', password: '', role: 'admin', status: 'verification' }}
            onFinish={updateUser}
            layout='horizontal'
          >
            <Form.Item
              name='email'
              label='Email'
              rules={[
                { required: true, message: 'Email is required!' },
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
              ]}
            >
              <Input type='email' />
            </Form.Item>

            <Form.Item name='role' label='Role' rules={[{ required: true, message: 'Role is required!' }]}>
              <Select>
                <Select.Option value='admin'>Admin</Select.Option>
                <Select.Option value='superadmin'>Super Admin</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name='status' label='Status' rules={[{ required: true, message: 'Status is required!' }]}>
              <Select disabled={props.match.params.id === 'new'}>
                <Select.Option value='verification'>Verification</Select.Option>
                <Select.Option value='active'>Active</Select.Option>
                <Select.Option value='blocked'>Blocked</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name='username' label='Username'>
              <Input type='text' />
            </Form.Item>

            <Form.Item name='firstName' label='First Name'>
              <Input type='text' />
            </Form.Item>

            <Form.Item name='lastName' label='Last Name'>
              <Input type='text' />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ textAlign: 'center' }}>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>

              <Button type='secondary' style={{ marginLeft: '1rem' }} onClick={onCancel}>
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </Dashboard>
  );
};

export default User;
