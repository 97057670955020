import React, { useState } from 'react';
import Axios from 'axios';
import { useAppContext } from '../context/AppContext';
import { Spin, message, Table, Button, Form, Input, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../config';
import confirmModal from './confirm';

const EditLanguage = () => {
  const [isFormVisible, setFormVisible] = useState(false);
  const [editId, setEditId] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [form] = Form.useForm();

  const { languages, setFetchTrigger } = useAppContext();

  const editHandler = (record) => {
    if (record) {
      form.setFieldsValue({ name: record.name, nativeName: record.nativeName });
      setEditId(record._id);
    } else {
      form.setFieldsValue({ name: null });
      setEditId(null);
    }
    setTimeout(() => setFormVisible(true));
  };

  const onEditCancel = () => {
    form.resetFields();
    setFormVisible(false);
    setEditId(null);
  };

  const updateLanguage = async ({ name, nativeName }, id) => {
    setUpdating(true);
    try {
      const method = id ? 'put' : 'post';
      const route = id ? `${SERVER_URL}/v2/languages/${id}` : `${SERVER_URL}/v2/languages`;

      await Axios[method](
        route,
        { name, nativeName },
        {
          withCredentials: true,
        }
      );

      message.success(`${id ? 'Updated' : 'Saved'} ${name} language`);
      setFetchTrigger((prev) => !prev);
      onEditCancel();
      setUpdating(false);
    } catch (err) {
      console.log('Language update error: ', err.message || err.response.data.message);
      message.error(`Error ${id ? 'updating' : 'saving'} ${name} language! Try again later...`);
      setUpdating(false);
    }
  };

  const deleteLanguage = async (id, name) => {
    setUpdating(true);
    try {
      const res = await Axios.delete(`${SERVER_URL}/v2/languages/${id}`, {
        withCredentials: true,
      });

      message.success(`${res?.data?.name || 'Language'} deleted`);
      setFetchTrigger((prev) => !prev);
      onEditCancel();
      setUpdating(false);
    } catch (err) {
      console.log('Language delete error: ', err.message || err.response.data.message);
      message.error('Error deleting language! Try again later...');
      setUpdating(false);
    }
  };

  const columns = [
    {
      title: 'Language',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Native Name',
      dataIndex: 'nativeName',
      key: 'nativeName',
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => text && new Date(text).toLocaleDateString(),
      responsive: ['md'],
    },
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => text && new Date(text).toLocaleDateString(),
      responsive: ['md'],
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_, record) => (
        <div style={{ textAlign: 'center', zIndex: 9999 }}>
          <span
            onClick={() => editHandler(record)}
            title={`Edit ${record.name} language`}
            style={{
              padding: '0.225rem',
              margin: '0.125rem',
              color: '#1890ff',
              textAlign: 'center',
              cursor: 'pointer',
              fontSize: '1.125rem',
            }}
          >
            <EditOutlined />
          </span>

          <Popconfirm
            placement='left'
            title={`Are You sure You want to delete ${record.name} language?`}
            onConfirm={() =>
              confirmModal({
                title: <strong>You are about to delete {record.name} language</strong>,
                content: (
                  <p>
                    This action is irreversible.
                    <br />
                    Are You sure You want to proceed?
                  </p>
                ),
                onOk: () => deleteLanguage(record._id),
                onCancel: () => {},
              })
            }
          >
            <span
              title={`Delete ${record.name} language`}
              style={{
                padding: '0.225rem',
                margin: '0.125rem',
                color: 'red',
                textAlign: 'center',
                cursor: 'pointer',
                fontSize: '1.125rem',
              }}
            >
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Spin spinning={updating} tip='Working...'>
      <div style={{ marginBottom: '2rem', display: 'flex', flexDirection: 'row' }}>
        <Button type='primary' onClick={() => editHandler()} disabled={updating}>
          Add new language
        </Button>

        {isFormVisible && (
          <div style={{ marginLeft: '5rem' }}>
            <Form form={form} onFinish={(values) => updateLanguage(values, editId)} layout='inline'>
              <Form.Item name='name' label='Name' rules={[{ required: true, message: 'Language name is required!' }]}>
                <Input type='text' />
              </Form.Item>

              <Form.Item name='nativeName' label='Native Name'>
                <Input type='text' />
              </Form.Item>

              <Form.Item>
                <Button type='primary' htmlType='submit'>
                  Submit
                </Button>

                <Button type='secondary' style={{ marginLeft: '1rem' }} onClick={onEditCancel}>
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>

      <div style={{ overflowX: 'auto' }}>
        <Table
          bordered
          rowKey={(record) => record._id}
          dataSource={languages}
          loading={updating}
          columns={columns}
          size='small'
          pagination={{ pageSize: '20', size: 'small', hideOnSinglePage: true }}
        />
      </div>
    </Spin>
  );
};

export default EditLanguage;
