import React from 'react';
import { Layout } from 'antd';
import Navbar from './Navbar';
const { Header, Content } = Layout;

const Dashboard = ({ children, current, setLoggedIn, isAdmin, fetchTrigger }) => {
  return (
    <Layout style={{ background: 'white' }}>
      <Header
        style={{
          background: 'white',
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          height: '66px',
          borderBottom: '1px solid #eee',
          boxShadow: '0 3px 3px #eee',
          padding: 0,
        }}
      >
        <Navbar current={current} setLoggedIn={setLoggedIn} isAdmin={isAdmin} fetchTrigger={fetchTrigger} />
      </Header>

      <Content style={{ margin: '80px auto 40px', width: '100%', maxWidth: '1280px' }}>{children}</Content>
    </Layout>
  );
};

export default Dashboard;
