import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { useAppContext } from '../context/AppContext';
import { /* Popconfirm, */ Spin, message, Table, Button, Form, Select } from 'antd';
import { /* DeleteOutlined, */ EditOutlined } from '@ant-design/icons';
import { SERVER_URL } from '../config';
import EditTranslationModal from './EditTranslationModal';

const EditTranslation = () => {
  const { languages, setFetchTrigger, fetchTrigger } = useAppContext();
  const [translations, setTranslations] = useState([]);
  const [allStitches, setAllStitches] = useState([]);
  const [notTranslated, setNotTranslated] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]._id);
  const [isFormVisible, setFormVisible] = useState(false);
  const [editId, setEditId] = useState(null);
  const [editName, setEditName] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const fetchTranslations = async () => {
      setUpdating(true);
      try {
        const { data } = await Axios.get(`${SERVER_URL}/v2/translations?language=${selectedLanguage}`, {
          withCredentials: true,
        });
        if (data) setTranslations(data);
        setUpdating(false);
      } catch (error) {
        console.log(error);
        setUpdating(false);
      }
    };

    fetchTranslations();
  }, [fetchTrigger, selectedLanguage]);

  useEffect(() => {
    const fetchStitches = async () => {
      try {
        const { data } = await Axios.get(`${SERVER_URL}/v2/stitches`, { withCredentials: true });
        if (data) {
          setAllStitches(data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchStitches();
  }, [fetchTrigger, selectedLanguage]);

  useEffect(() => {
    const translatedStitches = translations.map((translation) => translation.stitch._id);
    const notTranslated = allStitches.filter((st) => !translatedStitches.includes(st._id));
    setNotTranslated(notTranslated);
  }, [translations, allStitches]);

  const editHandler = (record) => {
    if (record) {
      form.setFieldsValue({
        name: record.name,
        description: record.description,
        application: record.application,
        language: record.language._id,
        stitch: record.stitch._id,
      });
      setEditId(record._id);
      setEditName(`${record.stitch.appNumber} - ${record.name}`);
    } else {
      form.setFieldsValue({
        name: null,
        description: null,
        application: null,
        language: selectedLanguage,
        stitch: null,
      });
      setEditId(null);
      setEditName(null);
    }
    setTimeout(() => setFormVisible(true));
  };

  const onEditCancel = () => {
    form.resetFields();
    setFormVisible(false);
    setEditId(null);
    setEditName(null);
  };

  const updateTranslation = async (values, id) => {
    const updateValues = { ...values, language: selectedLanguage };
    setUpdating(true);
    try {
      const method = id ? 'put' : 'post';
      const route = id ? `${SERVER_URL}/v2/translations/${id}` : `${SERVER_URL}/v2/translations`;

      await Axios[method](route, updateValues, {
        withCredentials: true,
      });

      message.success(`${id ? 'Updated' : 'Saved'} ${values.name} translation`);
      // setTrigger((prev) => !prev);
      setFetchTrigger((prev) => !prev);
      onEditCancel();
      setUpdating(false);
    } catch (err) {
      console.log('Translation update error: ', err.message || err.response.data.message);
      message.error(`Error ${id ? 'updating' : 'saving'} ${values.name} translation! Try again later...`);
      setUpdating(false);
    }
  };

  // ***** Delete translation feature - might be used in future at the request of the client

  // const deleteTranslation = async (id, name) => {
  //   setUpdating(true);
  //   try {
  //     const res = await Axios.delete(`${SERVER_URL}/v2/translations/${id}`, {
  //       withCredentials: true,
  //     });

  //     message.success(`${res?.data?.name || 'Translation'} deleted`);
  //     setTrigger((prev) => !prev);
  //     onEditCancel();
  //     setUpdating(false);
  //   } catch (err) {
  //     console.log('Translation delete error: ', err.message || err.response.data.message);
  //     message.error('Error deleting translation! Try again later...');
  //     setUpdating(false);
  //   }
  // };

  const columns = [
    {
      title: 'Language',
      dataIndex: ['language', 'name'],
      key: 'name',
    },
    {
      title: 'Stitch',
      dataIndex: ['stitch', 'appNumber'],
      key: 'appNumber',
    },
    {
      title: 'Image',
      dataIndex: ['stitch', 'image'],
      align: 'center',
      render: (url, record) => url && <img src={url} alt={record.name} height={20} />,
      key: 'image',
      responsive: ['md'],
    },
    {
      title: 'Name',
      dataIndex: ['name'],
      key: 'name',
      responsive: ['md'],
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => text && new Date(text).toLocaleDateString(),
      responsive: ['md'],
    },
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => text && new Date(text).toLocaleDateString(),
      responsive: ['md'],
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_, record) => (
        <div style={{ textAlign: 'center', zIndex: 9999 }}>
          <span
            onClick={() => editHandler(record)}
            title={`Edit ${record.name}`}
            style={{
              padding: '0.225rem',
              margin: '0.125rem',
              color: '#1890ff',
              textAlign: 'center',
              cursor: 'pointer',
              fontSize: '1.125rem',
            }}
          >
            <EditOutlined />
          </span>

          {/* DELETE TRANSLATION BUTTON */}

          {/* <Popconfirm
            placement='left'
            title={`Are You sure You want to delete ${record.name}?`}
            onConfirm={() => deleteTranslation(record._id)}
          >
            <span
              title={`Delete ${record.name}`}
              style={{
                padding: '0.225rem',
                margin: '0.125rem',
                color: 'red',
                textAlign: 'center',
                cursor: 'pointer',
                fontSize: '1.125rem',
              }}
            >
              <DeleteOutlined />
            </span>
          </Popconfirm> */}
        </div>
      ),
    },
  ];

  return (
    <Spin spinning={updating} tip='Working...'>
      <div style={{ marginBottom: '2rem', display: 'flex', flexDirection: 'row' }}>
        <Select
          value={selectedLanguage}
          onChange={(value) => setSelectedLanguage(value)}
          showSearch
          optionFilterProp='children'
          style={{ width: '150px', marginRight: '1rem' }}
        >
          {languages?.length > 0 &&
            languages.map((lang) => (
              <Select.Option value={lang._id} key={lang._id}>
                {lang.name}
              </Select.Option>
            ))}
        </Select>

        <Button disabled={notTranslated?.length === 0 || updating} type='primary' onClick={() => editHandler()}>
          Add new translation
        </Button>
      </div>

      <div style={{ overflowX: 'auto' }}>
        <Table
          bordered
          rowKey={(record) => record._id}
          dataSource={translations}
          loading={updating}
          columns={columns}
          size='small'
          pagination={{ pageSize: '20', size: 'small', hideOnSinglePage: true }}
        />
      </div>

      <EditTranslationModal
        visible={isFormVisible}
        id={editId}
        stitches={editId ? allStitches : notTranslated}
        name={editName}
        setFormVisible={setFormVisible}
        form={form}
        onEditCancel={onEditCancel}
        updateTranslation={updateTranslation}
      />
    </Spin>
  );
};

export default EditTranslation;
