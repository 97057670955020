import { createContext, useContext, useEffect, useState } from 'react';
import Axios from 'axios';
import { SERVER_URL } from '../config';

const Context = createContext();

export default function AppContext({ children }) {
  const [languages, setLanguages] = useState([]);
  const [languageGroups, setLanguageGroups] = useState([]);
  const [fetchTrigger, setFetchTrigger] = useState(true);
  const [working, setWorking] = useState(false);
  const [pdfGenerating, setPdfGenerating] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setWorking(true);
      try {
        const { data } = await Axios.get(`${SERVER_URL}/v2/languages`, { withCredentials: true });
        if (data) setLanguages(data);
        setWorking(false);
      } catch (error) {
        console.log(error);
        setWorking(false);
      }
      try {
        const { data } = await Axios.get(`${SERVER_URL}/v2/language-groups`, { withCredentials: true });
        if (data) setLanguageGroups(data);
        setWorking(false);
      } catch (error) {
        console.log(error);
        setWorking(false);
      }
    };

    fetchData();
  }, [fetchTrigger]);

  return (
    <Context.Provider
      value={{ languages, languageGroups, working, fetchTrigger, setFetchTrigger, pdfGenerating, setPdfGenerating }}
    >
      {children}
    </Context.Provider>
  );
}

export function useAppContext() {
  return useContext(Context);
}
