import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { useAppContext } from '../context/AppContext';
import axios from 'axios';
import { Menu, Button, Row, Col, message } from 'antd';
import { SERVER_URL } from '../config';

const { SubMenu } = Menu;

const Navbar = ({ current, setLoggedIn, isAdmin }) => {
  const { languages, languageGroups, pdfGenerating } = useAppContext();

  const logout = async () => {
    const logoutRes = await axios.get(`${SERVER_URL}/logout`, { withCredentials: true });
    if (logoutRes.data.message === 'Logged out') {
      sessionStorage.removeItem('loggedInUntil');
      sessionStorage.removeItem('role');
      sessionStorage.removeItem('email');
      setLoggedIn(false);
    } else {
      console.log('LOGOUT ERROR', logoutRes.data);
    }
  };

  if (languages.isError && languages.message.includes('401')) {
    message.warning('Session expired, please log in again!', 3);
    setLoggedIn(false);
    return <Redirect to='/login' />;
  }

  return (
    <Row justify='space-between' style={{ maxWidth: '1280px', margin: '0 auto' }}>
      <Col span={12}>
        <Menu mode='horizontal' selectedKeys={current}>
          <SubMenu key='single' title='Language' disabled={pdfGenerating}>
            {languages?.length > 0 &&
              languages.map((lang) => (
                <Menu.Item key={lang.name} style={{ height: '2rem', lineHeight: '2rem' }}>
                  <NavLink to={`/single/${lang.name}`} title={lang.name}>
                    {lang.name}
                  </NavLink>
                </Menu.Item>
              ))}
          </SubMenu>

          <SubMenu key='multi' title='Group' disabled={pdfGenerating}>
            {languageGroups?.length > 0 &&
              languageGroups.map((group) => (
                <Menu.Item key={group.name} style={{ height: '2rem' }}>
                  <NavLink to={`/multi/${group.name}`} title={group.name}>
                    {group.name} ({group.languages.map((l) => l.name).join(', ')})
                  </NavLink>
                </Menu.Item>
              ))}
          </SubMenu>

          <Menu.Item key='edit' title='Edit Content' disabled={pdfGenerating}>
            <NavLink to='/edit'>Edit</NavLink>
          </Menu.Item>

          {isAdmin && (
            <Menu.Item key='users' title='User Management' disabled={pdfGenerating}>
              <NavLink to='/users'>Users</NavLink>
            </Menu.Item>
          )}
        </Menu>
      </Col>

      <Col>
        <Button title='Log out' onClick={logout} disabled={pdfGenerating}>
          Log out
        </Button>
      </Col>
    </Row>
  );
};

export default Navbar;
