import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../config';

const passwordRegex = /^(?=.*\d)(?=.*[!@#$%^&*(){}[\]])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{7,}$/g;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const AccountVerification = (props) => {
  const { token } = props.match?.params;
  const history = useHistory();

  const onFinish = async (values) => {
    try {
      await Axios.post(`${SERVER_URL}/account-verification/${token}`, values, { withCredentials: true });
      message.info('Account verified', 2);
      setTimeout(() => history.push('/login'), 2150);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message;
      message.error(msg);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div style={{ maxWidth: '600px', padding: '3rem 1rem', margin: '0 auto' }}>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <h1 style={{ marginBottom: '2rem' }}>Singer Stitch App - Account Verification</h1>
        <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Please enter your Email!' }]}>
            <Input />
          </Form.Item>

          <Form.Item
            name='password'
            label='Password'
            rules={[
              {
                required: true,
                message: 'Password is required!',
              },
              () => ({
                validator(_, value) {
                  if (!value || !value.match(passwordRegex)) {
                    return Promise.reject(
                      new Error(
                        'Password should be at least 7 characters long, have at least 1 uppercase letter, 1 number and 1 special character'
                      )
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name='confirm'
            label='Confirm Password'
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AccountVerification;
