import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import AppContext from './context/AppContext';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login';
import AccountVerification from './components/AccountVerification';
import ResetPassword from './components/ResetPassword';
import SingleLanguage from './components/SingleLanguage';
import MultiLanguage from './components/MultiLanguage';
import Edit from './components/Edit';
import Users from './components/Users';
import User from './components/User';
import Axios from 'axios';
import { message } from 'antd';
import { SERVER_URL } from './config';

const App = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const userRole = sessionStorage.getItem('role');
  const [isAdmin, setIsAdmin] = useState(userRole === 'superadmin');
  const loggedInUntil = sessionStorage.getItem('loggedInUntil');

  useEffect(() => {
    if (new Date() > new Date(loggedInUntil) && loggedInUntil !== null) {
      console.log('Session expired!');
      setLoggedIn(false);
    } else if (loggedInUntil === null) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [setLoggedIn, loggedInUntil]);

  const handleLogin = async (user) => {
    try {
      const userResponse = await Axios.post(
        `${SERVER_URL}/login`,
        { username: user.username, password: user.password },
        { withCredentials: true }
      );

      if (userResponse.data.loggedIn) {
        setLoggedIn(true);
        sessionStorage.setItem('loggedInUntil', userResponse.data.expires);

        setIsAdmin(userResponse.data.role === 'superadmin');
        sessionStorage.setItem('role', userResponse.data.role);
        sessionStorage.setItem('email', userResponse.data.email);
      }
    } catch (err) {
      console.log('\n Login error = ', err.message);
      if (err.message.includes('401')) return message.warning('Wrong credentials or blocked!', 3);
    }
  };

  const routeProps = {
    isLoggedIn: isLoggedIn,
    setLoggedIn: setLoggedIn,
    isAdmin: isAdmin,
  };

  return (
    <AppContext>
      <Router>
        <Switch>
          <Route
            exact
            path='/login'
            render={() =>
              isLoggedIn ? ( // if signed redirect to single/English
                <Redirect to='/single/English' />
              ) : (
                <Login handleLogin={handleLogin} /> // else render login component
              )
            }
          />

          <Route
            exact
            path='/account-verification/:token'
            render={(props) =>
              isLoggedIn ? ( // if signed redirect to single/English
                <Redirect to='/single/English' />
              ) : (
                <AccountVerification {...props} /> // else render component
              )
            }
          />

          <Route
            exact
            path='/reset-password/:token'
            render={(props) =>
              isLoggedIn ? ( // if signed redirect to single/English
                <Redirect to='/single/English' />
              ) : (
                <ResetPassword {...props} /> // else render component
              )
            }
          />

          <ProtectedRoute exact path='/single/:langId' component={SingleLanguage} {...routeProps} />

          <ProtectedRoute exact path='/multi/:groupId' component={MultiLanguage} {...routeProps} />

          <ProtectedRoute exact path='/edit' component={Edit} {...routeProps} />

          <ProtectedRoute exact path='/users' component={Users} {...routeProps} allowed='superadmin' />

          <ProtectedRoute exact path='/user/:id' component={User} {...routeProps} allowed='superadmin' />

          <ProtectedRoute exact path='/' {...routeProps}>
            <Redirect to='/single/English' />
          </ProtectedRoute>

          <ProtectedRoute exact path='/single' {...routeProps}>
            <Redirect to='/single/English' />
          </ProtectedRoute>

          <ProtectedRoute exact path='/multi' {...routeProps}>
            <Redirect to='/multi/NA' />
          </ProtectedRoute>

          <Route render={() => <Redirect to='/' />} />
        </Switch>
      </Router>
    </AppContext>
  );
};

export default App;
